import React, { Fragment } from "react";
import { Button } from "@mui/material";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { ReactComponent as TrashIcon } from "assets/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantConfig } from "features/config/configSlice";
import {
  addProceedBool,
  getSelectedGroupProducts,
  mobSelected,
} from "features/product/productSlice";
import { addReturnedProducts } from "features/returnedProducts/returnedProductSlice";
import { updateWebSelected } from "features/orders/orderSlice";

function CartProducts({ product, location, returnedProducts, outOfStock }) {
  const merchantConfig = useSelector(getMerchantConfig);
  const selectedGroupProducts = useSelector(getSelectedGroupProducts);

  const dispatch = useDispatch();

  const removeReturnedItem = (line_item_id) => {
    const line_item_ids = returnedProducts.map((a) => a.line_item_id);
    const tempProducts = [...returnedProducts];

    if (selectedGroupProducts.length > 0) {
      for (let i = selectedGroupProducts.length - 1; i >= 0; i--) {
        const product = selectedGroupProducts[i];

        const index = line_item_ids.indexOf(product.line_item_id);

        if (index !== -1) {
          tempProducts.splice(index, 1);
          line_item_ids.splice(index, 1);
        }
      }
    } else {
      const index = line_item_ids.indexOf(line_item_id);
      tempProducts.splice(index, 1);
    }

    dispatch(addReturnedProducts([...tempProducts]));
  };

  const handleEdit = () => {
    dispatch(addProceedBool(false));
    if (window.innerWidth < 800) {
      dispatch(mobSelected(true));
    } else {
      dispatch(updateWebSelected(true));
    }
  };

  return (
    <div
      className="cart-wrapper"
      style={{
        background: `${merchantConfig.primary_theme_color}22`,
        padding: "10px 1rem",
      }}
    >
      <div className="exchange-wrapper">
        <div className="exchange-box">
          <p className="mob-ex">
            {product.return_type === "exchange" ? "Exchange" : "Return"}
          </p>
          <img
            className="image-preview"
            src={product.variant_image_url}
            alt="Preview"
          />
          <div className="details">
            <p className="title">{product.product_title}</p>
            <p className="description">
              {product.variant_name !== null &&
              product.variant_name.indexOf("Default") === -1
                ? product.variant_name
                : product.product_title}
            </p>
            <p className="quantity">Qty: {product.quantity}</p>
            <p className="price">
              {product.total_item_discount > 0 ? (
                <span>
                  ₹ {product.discounted_price}{" "}
                  <span
                    style={{
                      textDecoration: "line-through",
                      fontSize: "14px",
                      marginLeft: "4px",
                    }}
                  >
                    ₹{product.original_product_price}
                  </span>
                </span>
              ) : (
                `₹ ${product.product_price}`
              )}
            </p>
          </div>
        </div>

        {/* <div className="return-icon">
          {product.return_type === "exchange" ? (
            <RepeatIcon
              style={{
                color: merchantConfig.primary_theme_color,
                background: `${merchantConfig.primary_theme_color}33`,
                borderRadius: "50%",
                padding: "8px",
                fontSize: "40px",
              }}
            />
          ) : (
            <ReplayIcon
              style={{
                color: merchantConfig.primary_theme_color,
                background: `${merchantConfig.primary_theme_color}33`,
                borderRadius: "50%",
                padding: "8px",
                fontSize: "40px",
              }}
            />
          )}
        </div> */}

        <div className="exchange-box">
          {product.return_type === "exchange" && (
            <Fragment>
              <p className="mob-ex">You Get</p>
              <img
                className="image-preview"
                src={product.exchange_variant_image_url}
                alt="Preview"
              />
              <div className="details">
                {outOfStock !== undefined && outOfStock !== null && (
                  <p className="stock">Out of Stock</p>
                )}
                <p className="title">{product.exchange_product_title}</p>
                <p className="description">
                  {product.variant_name !== null &&
                  product.variant_name.indexOf("Default") === -1
                    ? product.exchange_variant_name
                    : product.exchange_product_title}
                </p>
                <p className="quantity">Qty: {product.quantity}</p>

                {product.variant_id !== product.exchange_variant_id &&
                  product.exchange_type == "different" && (
                    <p className="price">₹ {product.exchange_product_price}</p>
                  )}
              </div>
            </Fragment>
          )}
        </div>
      </div>

      {/* {product.return_type && location==="/tracking" && <p style={{color: '#333333'}} className='return-type'>Return Type : <span style={{textTransform: 'capitalize',color: '#333333B2'}}> {product.return_type}</span></p>} */}
      {product.customer_reason_label && (
        <p
          style={{ color: "#333333", margin: "10px 0px" }}
          className="return-reason"
        >
          Reason for {product.return_type}:{" "}
          <span style={{ color: "#333333B2" }}>
            {" "}
            {product.customer_reason_label}{" "}
          </span>
        </p>
      )}

      {(location === "/return-cart" || location === "/products") && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <p style={{fontSize: '12px', textDecoration: 'underline', color: '#111111'}}>View Price Details</p> */}
          {/* <Button
            color="primary"
            onClick={() => handleEdit()}
            style={{ textTransform: "capitalize" }}
            startIcon={
              <EditIcon
                style={{ stroke: merchantConfig.primary_theme_color }}
              />
            }
          >
            {" "}
            Edit{" "}
          </Button> */}
          <Button
            color="primary"
            onClick={() => removeReturnedItem(product.line_item_id)}
            startIcon={
              <TrashIcon
                style={{ stroke: merchantConfig.primary_theme_color }}
              />
            }
            style={{ textTransform: "capitalize" }}
          >
            {" "}
            Remove{" "}
          </Button>
        </div>
      )}
    </div>
  );
}

export default CartProducts;
