import React, { Fragment, useEffect, useState } from "react";
import Header from "../common/Header";
import {
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  StyledMobButtons,
  StyledProducts,
  StyledProductsWrapper,
} from "./styled";
import ReplayIcon from "@mui/icons-material/Replay";
import RepeatIcon from "@mui/icons-material/Repeat";
import Reason from "./reason";
import { motion } from "framer-motion";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getMerchantConfig, getRmsConfig } from "features/config/configSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedOrder,
  getWebSelected,
  updateSelectedOrder,
  updateUUID,
  updateWebSelected,
} from "features/orders/orderSlice";
import { selectedOrderAPI } from "utils/api";
import {
  addSelectedProduct,
  addProductid,
  getProductid,
  getSelectedMob,
  mobSelected,
  addSelectedReason,
  addSelectedSubReason,
  setImageUploads,
  addPreviews,
  addUploads,
  addProductType,
  addProceedBool,
  addSelectedGroupProducts,
} from "features/product/productSlice";
import {
  addRefundJSON,
  addRefundSource,
  getReturnedProducts,
} from "features/returnedProducts/returnedProductSlice";
import { getEmail, getPhone } from "features/user/userSlice";
import { ReactComponent as ErrorIcon } from "assets/error.svg";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CartProducts from "components/common/CartProducts/CartProducts";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import cartImage from "assets/cart.svg";

function ProductsDisplay() {
  const merchantConfig = useSelector(getMerchantConfig);
  const defaultSelectedOrder = useSelector(getSelectedOrder);
  const selected = useSelector(getSelectedMob);
  const returnedProducts = useSelector(getReturnedProducts);
  const rmsConfig = useSelector(getRmsConfig);
  const webSelected = useSelector(getWebSelected);
  const userPhone = useSelector(getPhone);
  const userEmail = useSelector(getEmail);
  const defaultProductid = useSelector(getProductid);

  const [productid, setProductid] = useState(defaultProductid);
  const [selectedOrder, setSelectedOrder] = useState(defaultSelectedOrder);
  const [productsLoading, setProductsLoading] = useState(true);
  const [apiErrorLoading, setApiErrorLoading] = useState(false);
  const [incentiviseExchangeModalOpen, setIncentiviseExchangeModalOpen] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [animate, setAnimate] = useState(false);
  const [updatedReturnedProducts, setUpdatedReturnedProducts] =
    useState(returnedProducts);

  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  function updateProductsList(order) {
    let productset = [];
    let updatedOrder = { ...order };

    if (returnedProducts !== undefined) {
      if (returnedProducts.length > 0) {
        productset = updatedOrder.order_items.filter(
          (a) =>
            !returnedProducts
              .map((a) => a.line_item_id)
              .includes(a.line_item_id)
        );
        let remainingProducts = updatedOrder.order_items.filter((a) =>
          returnedProducts.map((a) => a.line_item_id).includes(a.line_item_id)
        );
        for (let i = 0; i < returnedProducts.length; i++) {
          for (let j = 0; j < remainingProducts.length; j++) {
            if (
              returnedProducts[i].line_item_id ===
                remainingProducts[j].line_item_id &&
              returnedProducts[i].remaining_qty > 0
            ) {
              remainingProducts[j] = {
                ...remainingProducts[j],
                remaining_qty: returnedProducts[i].remaining_qty,
              };
              productset.push(remainingProducts[j]);
            }
          }
        }
        const returnedProductGroupIds = returnedProducts
          .map((a) => a.product_group_id)
          .filter((id) => id !== null && id !== undefined);

        updatedOrder.order_items = productset.filter(
          (product) =>
            !returnedProductGroupIds.includes(product.product_group_id)
        );
      }
      setSelectedOrder(updatedOrder);
    }
  }
  useEffect(() => {
    setUpdatedReturnedProducts(returnedProducts);
  }, [returnedProducts]);

  useEffect(() => {
    updateProductsList(defaultSelectedOrder);
  }, [updatedReturnedProducts]);

  useEffect(() => {
    if (location.pathname !== "/return-cart") {
      selectedOrderAPI(
        merchantConfig.merchant_id,
        selectedOrder.order_id,
        selectedOrder
      )
        .then((resp) => {
          dispatch(updateSelectedOrder(resp.data.order));
          dispatch(updateUUID(resp.data.unique_id));
          if (location.pathname === "/products") {
            updateProductsList(resp.data.order);
            // setSelectedOrder(resp.data.order);
          }
          setProductsLoading(false);
        })
        .catch((err) => {
          if (userPhone === "" && userEmail === "") {
            let url = "/";
            if (encipherencode !== null && encipherencode !== undefined) {
              url += `?encipherencode=${encipherencode}`;
            }
            navigate(url);
          } else {
            setApiErrorLoading(true);
          }
        });
      dispatch(updateWebSelected(false));
      dispatch(mobSelected(false));
      dispatch(addProductid(null));
      setProductid(null);
      dispatch(addRefundSource(null));
      dispatch(addRefundJSON({}));
    } else {
      setProductsLoading(false);
    }
  }, []);

  const styles = {
    infoMessage: {
      color: "#333333B2",
      fontSize: "14px",
      padding: "1rem",
    },
    button: {
      padding:
        rmsConfig.button_version === "v1"
          ? "10px 1rem"
          : rmsConfig.button_version === "v2"
          ? "10px 6px 10px 1rem"
          : "10px 1rem",
      borderRadius: merchantConfig.use_rounded_edge_buttons ? "4px" : "0px",
    },
    incentiviseMessage: {
      color: rmsConfig.button_promotional_text_color
        ? rmsConfig.button_promotional_text_color
        : "#16AC37",
    },
  };

  // Helper function to render buttons
  const renderButton = (
    label,
    onClick,
    disabled,
    icon,
    style = {},
    chargesStatement,
    modalButton
  ) => (
    <Button
      className={label === "Exchange" && modalButton && "exchange-button"}
      variant={label === "Exchange" ? "contained" : "outlined"}
      color="primary"
      onClick={onClick}
      disabled={disabled}
      startIcon={icon}
      style={{ ...styles.button, ...style }}
    >
      {label}
      <span
        style={{
          fontSize: "10px",
          color: "#f1416c",
          display: "flex",
        }}
      >
        {chargesStatement}
      </span>
    </Button>
  );

  const RefundButtons = ({ product, modalButton }) => {
    // Helper function to render restriction messages
    const renderRestrictionMessage = (message) => (
      <p className="info-message">{message}</p>
    );

    // Conditions
    if (!product.is_delivered || product.is_delivered == null) {
      return <p className="info-message">The item is yet to be delivered</p>;
    }

    if (product.is_delivered && product.delivery_date_expired) {
      return <p className="info-message">Return/Exchange period is expired</p>;
    }

    if (
      rmsConfig.restrict_multi_type_request_creation &&
      returnedProducts.length > 0 &&
      (product.refund_eligible || product.exchange_eligible) &&
      product.remaining_qty > 0
    ) {
      return (
        <div>
          <div className="button-container">
            {rmsConfig.supports_exchange &&
              !product.exchange_date_expired &&
              returnedProducts.some((a) => a.return_type === "exchange") &&
              renderButton(
                "Exchange",
                () => {
                  if (window.innerWidth > 900) {
                    dispatch(updateWebSelected(true));
                  } else {
                    dispatch(mobSelected(true));
                  }
                  handleOnSelect("exchange", product);
                  setIncentiviseExchangeModalOpen(false);
                },
                !product.exchange_eligible,
                <RepeatIcon />,
                {
                  width: rmsConfig.button_version === "v1" ? "50%" : "100%",
                },
                null,
                modalButton
              )}
            {rmsConfig.supports_refund &&
              !product.return_date_expired &&
              returnedProducts.some((a) => a.return_type === "refund") &&
              renderButton(
                rmsConfig.button_version !== "v2" ? "Return" : "",
                () => {
                  handleOnSelect("return", product);
                  if (
                    rmsConfig.exchange_nudge_on_return_click &&
                    !modalButton
                  ) {
                    setIncentiviseExchangeModalOpen(true);
                  } else {
                    if (window.innerWidth > 900) {
                      dispatch(updateWebSelected(true));
                    } else {
                      dispatch(mobSelected(true));
                    }
                    setIncentiviseExchangeModalOpen(false);
                  }
                },
                !product.refund_eligible,
                rmsConfig.button_version === "v3" ||
                  rmsConfig.button_version === "v4" ? (
                  ""
                ) : (
                  <ReplayIcon />
                ),
                {
                  width:
                    rmsConfig.button_version === "v1"
                      ? "50%"
                      : rmsConfig.button_version === "v4"
                      ? "200px"
                      : "auto",
                  display: "flex",
                  flexDirection:
                    rmsConfig.button_version === "v4" ? "column" : "row",
                  marginLeft: "10px",
                },
                rmsConfig.button_version === "v4" &&
                  rmsConfig.base_refund_charge > 0 &&
                  `Extra ₹${rmsConfig.base_refund_charge}*`,
                modalButton
              )}
            {rmsConfig.button_promotional_text &&
              rmsConfig.button_promotional_text !== "" && (
                <p
                  className="incentivise-message"
                  style={styles.incentiviseMessage}
                >
                  {rmsConfig.button_promotional_text}
                </p>
              )}
          </div>

          {!product.exchange_eligible &&
            product.exchange_restrict_msg &&
            renderRestrictionMessage(product.exchange_restrict_msg)}

          {!product.refund_eligible &&
            product.refund_restrict_msg &&
            renderRestrictionMessage(product.refund_restrict_msg)}
        </div>
      );
    }

    if (
      product.eligible &&
      (product.refund_eligible || product.exchange_eligible) &&
      product.remaining_qty > 0
    ) {
      return (
        <div>
          <div className="button-container">
            {rmsConfig.supports_exchange &&
              !product.exchange_date_expired &&
              renderButton(
                "Exchange",
                () => {
                  if (window.innerWidth > 900) {
                    dispatch(updateWebSelected(true));
                  } else {
                    dispatch(mobSelected(true));
                  }
                  handleOnSelect("exchange", product);
                  setIncentiviseExchangeModalOpen(false);
                },
                !product.exchange_eligible,
                <RepeatIcon />,
                {
                  width: rmsConfig.button_version === "v1" ? "50%" : "100%",
                },
                null,
                modalButton
              )}
            {rmsConfig.supports_refund &&
              !product.return_date_expired &&
              renderButton(
                rmsConfig.button_version !== "v2" ? "Return" : "",
                () => {
                  handleOnSelect("return", product);
                  if (
                    rmsConfig.exchange_nudge_on_return_click &&
                    !modalButton
                  ) {
                    setIncentiviseExchangeModalOpen(true);
                  } else {
                    if (window.innerWidth > 900) {
                      dispatch(updateWebSelected(true));
                    } else {
                      dispatch(mobSelected(true));
                    }
                    setIncentiviseExchangeModalOpen(false);
                  }
                },
                !product.refund_eligible,
                rmsConfig.button_version === "v3" ||
                  rmsConfig.button_version === "v4" ? (
                  ""
                ) : (
                  <ReplayIcon />
                ),
                {
                  width:
                    rmsConfig.button_version === "v1"
                      ? "50%"
                      : rmsConfig.button_version === "v4"
                      ? "200px"
                      : "auto",
                  display: "flex",
                  flexDirection:
                    rmsConfig.button_version === "v4" ? "column" : "row",
                  marginLeft: "10px",
                },
                rmsConfig.button_version === "v4" &&
                  rmsConfig.base_refund_charge > 0 &&
                  `Extra ₹${rmsConfig.base_refund_charge}*`,
                modalButton
              )}
          </div>

          {rmsConfig.button_promotional_text &&
            rmsConfig.button_promotional_text !== "" && (
              <p
                className="incentivise-message"
                style={styles.incentiviseMessage}
              >
                {rmsConfig.button_promotional_text}
              </p>
            )}

          {!product.exchange_eligible &&
            product.exchange_restrict_msg &&
            renderRestrictionMessage(product.exchange_restrict_msg)}

          {!product.refund_eligible &&
            product.refund_restrict_msg &&
            renderRestrictionMessage(product.refund_restrict_msg)}
        </div>
      );
    }

    if (product.remaining_qty > 0) {
      return (
        <p className="info-message">
          This item is not eligible for return or exchange, including items
          bought during a sale.
        </p>
      );
    }

    return (
      <p className="info-message">
        You've already placed a return/exchange for this item. Please reach out
        to us by clicking on the Need Help button at the bottom of the page for
        further assistance.
      </p>
    );
  };

  function handleOnSelect(return_type, product) {
    dispatch(addSelectedReason(null));
    dispatch(addSelectedSubReason(null));
    dispatch(setImageUploads([]));
    dispatch(addPreviews([]));
    dispatch(addUploads([]));
    dispatch(addProductType(null));
    dispatch(addProceedBool(false));

    if (product.product_group_id && product.product_group_id !== null) {
      let groupProducts = selectedOrder.order_items.filter(
        (newProduct) => newProduct.product_group_id === product.product_group_id
      );
      if (product.is_parent) {
        dispatch(addSelectedProduct(product));
        dispatch(addSelectedGroupProducts(groupProducts));
        dispatch(addProductid(product.line_item_id + return_type));
        setProductid(product.line_item_id + return_type);
        setSelectedProduct(product);
      } else {
        const parentProduct = groupProducts.filter(
          (groupProduct) => groupProduct.is_parent === true
        );

        if (parentProduct && parentProduct.length > 0) {
          dispatch(addSelectedProduct(parentProduct[0]));
          setSelectedProduct(parentProduct[0]);
          dispatch(addSelectedGroupProducts(groupProducts));
          dispatch(addProductid(parentProduct[0].line_item_id + return_type));
          setProductid(parentProduct[0].line_item_id + return_type);
        }
      }
    } else {
      dispatch(addSelectedProduct(product));
      setSelectedProduct(product);
      dispatch(addSelectedGroupProducts([]));
      dispatch(addProductid(product.line_item_id + return_type));
      setProductid(product.line_item_id + return_type);
    }
  }

  const ProductCard = ({ product, webSelected }) => {
    const currentProduct = selectedOrder.order_items.filter(
      (a) => a.line_item_id === product.line_item_id
    );

    return (
      <div
        style={{
          background: "#fff",
          borderBottom:
            product.line_item_id !== undefined &&
            productid !== null &&
            productid.includes(product.line_item_id)
              ? `1px solid ${merchantConfig.primary_theme_color}`
              : "1px solid #11111133",
          position: "relative",
          width: "100%",
          maxWidth: "700px",
        }}
      >
        <StyledProducts>
          {(currentProduct[0].quantity - product.quantity > 0 ||
            product.returned_qty > 0) &&
            product.remaining_qty > 0 && (
              <p
                className="top-banner"
                style={{
                  background: "#333",
                  color: "#fff",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  padding: "4px 8px",
                  fontSize: "12px",
                }}
              >
                {currentProduct[0].quantity - product.quantity || [
                  product.returned_qty,
                ]}{" "}
                qty added to return cart
              </p>
            )}

          <img
            className="product-image"
            src={product.image_url}
            alt={product.name}
          />
          <div className="product-details">
            <p
              style={{
                color: "#333",
                lineHeight: "1.5em",
                maxHeight: "3em",
                overflow: "hidden",
              }}
            >
              {product.product_title}
            </p>
            <p className="margin" style={{ color: "#333333B2" }}>
              {product.variant_name}
            </p>
            <p className="margin" style={{ color: "#333333B2" }}>
              Qty:{" "}
              {product.remaining_qty > 0
                ? product.remaining_qty
                : product.quantity}
            </p>
            <p
              className="margin"
              style={{ color: "#333333B2", fontWeight: "600" }}
            >
              {product.total_item_discount > 0 ? (
                <span>
                  ₹ {product.discounted_price}{" "}
                  <span
                    style={{
                      textDecoration: "line-through",
                      fontSize: "14px",
                      marginLeft: "4px",
                      fontWeight: "400",
                    }}
                  >
                    ₹{product.original_product_price}
                  </span>
                </span>
              ) : (
                `₹ ${product.product_price}`
              )}
            </p>
            {/* <div className="radio-buttons">
              <RefundButtons product={product} />
            </div> */}
          </div>
        </StyledProducts>
        {/* <StyledMobButtons> */}
        <RefundButtons product={product} />
        {/* </StyledMobButtons> */}
      </div>
    );
  };

  const spring = {
    type: "spring",
    stiffness: 50,
  };

  const StyledApp = styled.div`
    font-family: ${merchantConfig.google_font_family !== null
      ? merchantConfig.google_font_family
      : "Poppins,sans-serif"};
    .product-tag-consent {
      color: ${merchantConfig.secondary_theme_color};
      background: ${merchantConfig.primary_theme_color};
      width: 100%;
      text-align: center;
      position: fixed;
      top: 60px;
      padding: 1rem 10px;
      z-index: 25;
      line-height: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
    }

    @media (min-width: 800px) {
      .product-tag-consent {
        top: 70px;
      }
    }
  `;

  return (
    <StyledApp>
      {location.pathname === "/products" && (
        <Header
          ordername={
            selectedOrder && selectedOrder !== null && selectedOrder.order_name
          }
          disabled={updatedReturnedProducts.length === 0}
        />
      )}
      {rmsConfig.product_tag_consent &&
        rmsConfig.product_tag_consent_text !== "" &&
        location.pathname === "/products" && (
          <p className="product-tag-consent">
            {rmsConfig.product_tag_consent_text}
          </p>
        )}
      {apiErrorLoading ? (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "80vh",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <ErrorIcon />
          <p
            style={{
              marginTop: "25px",
              color: "#1d8dcc",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={() => window.location.reload()}
          >
            Reload
          </p>
        </div>
      ) : (
        <StyledProductsWrapper
          location={location.pathname}
          returnedProducts={returnedProducts}
          productConsent={rmsConfig.product_tag_consent}
        >
          {productsLoading ? (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {[...Array(4).keys()].map((a) => {
                return (
                  <div className="products-skeleton" key={a}>
                    <Skeleton
                      variant="rectangular"
                      className="image-skeleton"
                    />
                    <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                      <Typography className="text-skeleton" variant="h4">
                        {" "}
                        <Skeleton variant="text" />
                      </Typography>
                      <Typography className="text-skeleton" variant="h4">
                        {" "}
                        <Skeleton variant="text" />
                      </Typography>
                      <Typography className="text-skeleton" variant="h4">
                        {" "}
                        <Skeleton variant="text" />
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="products-wrapper">
              {selectedProduct && selectedProduct.image_url && animate && (
                <img
                  className="add-to-cart-image"
                  src={selectedProduct.image_url}
                />
              )}
              {updatedReturnedProducts.length > 0 && (
                <div className="products-return">
                  <Accordion className="accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                    >
                      <p style={{ fontWeight: "600", letterSpacing: "1px" }}>
                        {returnedProducts.length > 0
                          ? returnedProducts.length
                          : "No"}{" "}
                        item
                        {(returnedProducts.length > 1 ||
                          returnedProducts.length === 0) &&
                          "s"}{" "}
                        in the return cart
                      </p>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        maxHeight: "450px",
                        height: "100%",
                        overflowY: "auto",
                        padding: "0",
                      }}
                    >
                      <Grid
                        alignItems="center"
                        container
                        gridTemplateColumns="repeat(12, 1fr)"
                        className="cart-products"
                      >
                        {updatedReturnedProducts.length > 0 &&
                          updatedReturnedProducts.map((product, i) => {
                            return (
                              <Grid
                                key={i}
                                justifyContent="center"
                                item
                                xs={12}
                                style={{
                                  borderBottom: `1.5px solid rgba(17, 17, 17, 0.2)`,
                                }}
                              >
                                <CartProducts
                                  product={product}
                                  location={location.pathname}
                                  returnedProducts={updatedReturnedProducts}
                                  outOfStock={null}
                                />
                              </Grid>
                            );
                          })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              {location.pathname === "/products" && (
                <div>
                  {selectedOrder.order_items.length === 0 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: "1.5rem 0",
                        maxWidth: "700px",
                      }}
                    >
                      <img src={cartImage} style={{ height: "100px" }} />
                      <p
                        style={{
                          marginTop: "10px",
                          padding: "1rem",
                          fontWeight: "600",
                          textAlign: "center",
                          lineHeight: "1.5rem",
                          fontSize: "18px",
                        }}
                      >
                        Hooray! 🎉 Your cart is full and ready. <br />
                        Let's go !!!
                      </p>
                    </div>
                  ) : (
                    <div className="select-products">
                      {updatedReturnedProducts.length > 0
                        ? `Add More Items to ${
                            rmsConfig.supports_exchange &&
                            rmsConfig.supports_refund
                              ? " return or exchange"
                              : rmsConfig.supports_exchange
                              ? " exchange"
                              : " return"
                          }`
                        : `Select products for ${
                            rmsConfig.supports_exchange &&
                            rmsConfig.supports_refund
                              ? " return or exchange"
                              : rmsConfig.supports_exchange
                              ? " exchange"
                              : " return"
                          }`}
                    </div>
                  )}
                </div>
              )}
              <Grid direction="column" container rowSpacing={{ xs: 2, md: 4 }}>
                {selectedOrder &&
                  selectedOrder.order_items &&
                  selectedOrder.order_items
                    .filter((a) => a.visible && a.eligible)
                    .map((product, i) => {
                      return (
                        <Grid key={i} justifyContent="center" item xs={12}>
                          {/* <motion.div layout transition={spring}> */}
                          <ProductCard
                            product={product}
                            key={product.line_item_id}
                            webSelected={webSelected}
                          />
                          {/* </motion.div> */}
                        </Grid>
                      );
                    })}

                {selectedOrder &&
                  selectedOrder.order_items &&
                  selectedOrder.order_items
                    .filter((a) => !a.eligible)
                    .map((product, i) => {
                      return (
                        <Grid key={i} justifyContent="center" item xs={12}>
                          {/* <motion.div layout transition={spring}> */}
                          <ProductCard
                            product={product}
                            key={product.line_item_id}
                            webSelected={webSelected}
                          />
                          {/* </motion.div> */}
                        </Grid>
                      );
                    })}
              </Grid>
              {webSelected && (
                <div
                  style={{
                    margin: "auto",
                    position: "fixed",
                    width: "50%",
                    right: "0",
                    top:
                      location.pathname === "/return-cart" ? "118px" : "160px",
                    zIndex: "99",
                  }}
                >
                  <Reason
                    handleOnSelect={handleOnSelect}
                    setAnimate={setAnimate}
                  />
                </div>
              )}
              {selected && (
                <Drawer
                  style={{ maxHeight: "90vh" }}
                  anchor="bottom"
                  open={selected}
                  onClose={() => dispatch(mobSelected(true))}
                >
                  <Reason
                    handleOnSelect={handleOnSelect}
                    setAnimate={setAnimate}
                  />
                </Drawer>
              )}
              {location.pathname === "/products" && (
                <p onClick={() => navigate("/")} className="change-user">
                  Change Phone/Email ?
                </p>
              )}
              {location.pathname === "/products" &&
                merchantConfig.whatsapp_help &&
                merchantConfig.whatsapp_help !== null && (
                  <a
                    href={merchantConfig.whatsapp_help}
                    target="_blank"
                    rel="noreferrer"
                    className="help"
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      color: "var(--color-primary)",
                      margin: "60px 0px",
                      textDecoration: "underline",
                      display: "block",
                    }}
                  >
                    Need Help?
                  </a>
                )}

              {updatedReturnedProducts.length > 0 && (
                <div className="mob-view-cart-btn">
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      borderRadius: merchantConfig.use_rounded_edge_buttons
                        ? "4px"
                        : "0px",
                    }}
                    onClick={() => {
                      let url = "/return-cart";
                      if (
                        encipherencode !== null &&
                        encipherencode !== undefined
                      ) {
                        url += `?encipherencode=${encipherencode}`;
                      }
                      navigate(url);
                    }}
                  >
                    Proceed to Cart <ArrowRightAltIcon />
                  </Button>
                </div>
              )}
            </div>
          )}
        </StyledProductsWrapper>
      )}

      <Modal
        aria-labelledby="Incentvise Exchange"
        aria-describedby="Incentivising Exchange over Return of a product"
        open={incentiviseExchangeModalOpen}
        onClose={() => setIncentiviseExchangeModalOpen(false)}
        closeAfterTransition
        themePrimaryColor={merchantConfig.primary_theme_color}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "300px",
            padding: "20px 30px 30px",
            background: "#fff",
            maxWidth: "600px",
            borderRadius: "10px",
            width: "90%",
            fontFamily:
              merchantConfig.google_font_family !== null
                ? merchantConfig.google_font_family
                : "Poppins,sans-serif",
          }}
        >
          <div
            style={{ textAlign: "right", cursor: "pointer" }}
            onClick={() => setIncentiviseExchangeModalOpen(false)}
          >
            <CloseIcon />
          </div>
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <p className="nudge-title">{rmsConfig.exchange_nudge_title}</p>
            <p className="nudge-description">
              {rmsConfig.exchange_nudge_description}
            </p>
            <div style={{ marginTop: "20px" }}>
              {rmsConfig.supports_exchange &&
                !selectedProduct.exchange_date_expired &&
                renderButton(
                  "Exchange",
                  () => {
                    if (window.innerWidth > 900) {
                      dispatch(updateWebSelected(true));
                    } else {
                      dispatch(mobSelected(true));
                    }
                    handleOnSelect("exchange", selectedProduct);
                    setIncentiviseExchangeModalOpen(false);
                  },
                  !selectedProduct.exchange_eligible,
                  <RepeatIcon />,
                  {
                    width: "100%",
                  },
                  null,
                  true
                )}
              <p
                style={{
                  color: merchantConfig.primary_theme_color,
                  marginTop: "10px",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (window.innerWidth > 900) {
                    dispatch(updateWebSelected(true));
                  } else {
                    dispatch(mobSelected(true));
                  }
                  setIncentiviseExchangeModalOpen(false);
                }}
              >
                No, thanks! I'll proceed with the return.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </StyledApp>
  );
}

export default ProductsDisplay;
