import React from "react";
import { useSelector } from "react-redux";
import Header from "../common/Header";
import OrdersList from "./OrdersList";
import { StyledBody } from "./styled";
import { getMerchantConfig } from "features/config/configSlice";

function Orders({ setLoginLoader }) {
  const merchantConfig = useSelector(getMerchantConfig);
  return (
    <StyledBody
      customFont={
        merchantConfig.google_font_family !== null
          ? merchantConfig.google_font_family
          : "Poppins,sans-serif"
      }
    >
      <Header />
      <OrdersList setLoginLoader={setLoginLoader} />
    </StyledBody>
  );
}

export default Orders;
