import React from "react";
import { StyledFooterWrapper } from "./styled";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMerchantConfig } from "features/config/configSlice";

function Footer() {
  const location = useLocation();
  const merchantConfig = useSelector(getMerchantConfig);
  return (
    <StyledFooterWrapper location={location.pathname}>
      Powered by
      <div>
        <a
          href="https://bepragma.ai/?utm_source=RMS&utm_medium=entrypage&utm_campaign=footer"
          target="_blank"
          rel="noreferrer"
        >
          {(location.pathname === "/" || location.pathname === "/returns") &&
          merchantConfig?.public_page_theme_configurations?.cover_image_width ===
            100 ? (
            <img
              src="https://pragma-static-duke.s3.amazonaws.com/static/metronic/assets/media/logos/pragma-default-dark.svg"
              alt="PRAGMA."
            />
          ) : (
            <img
              src="https://pragma-staticfiles-prod.s3.amazonaws.com/static/metronic/assets/media/logos/pragma-default-light.svg"
              alt="PRAGMA."
            />
          )}
        </a>
      </div>
    </StyledFooterWrapper>
  );
}

export default Footer;
