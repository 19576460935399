import styled from "styled-components";

export const StyledLogin = styled.div`
  height: 100vh;
  ul,
  ol {
    padding-left: 24px;
  }

  .error-screen-web {
    display: block;
  }

  .error-screen-mob {
    display: none;
  }

  .hero-logo {
    display: ${(props) =>
      props.desktopCoverImageVisibility ? "flex" : "none"};
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  .login-wrapper {
    background: ${(props) =>
      `url(${props.coverImage}) center / cover no-repeat`};
    object-fit: cover;
    display: flex;
    align-items: center;
    padding: 5rem;
    height: 100vh;
  }

  .login-section {
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: ${(props) =>
      props.desktopCoverImageVisibility ? "20px 20px 0px" : "0px 6rem"};

    .brand-logo {
      display: ${(props) =>
        props.desktopBrandImageVisibility ? "block" : "none"};
      max-height: 60px;
      margin: 0 auto 1rem;
      width: 100%;
    }

    .return-title {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }

    .input-label {
      font-size: 16px;
      margin: 10px 0px;
      color: #717171;
    }

    .brand-note {
      max-width: 450px;
      width: 100%;
      font-size: 14px;
      line-height: 2;
      margin-top: 30px;
    }
  }

  .otp-input {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    font-size: 22px;
    text-align: center;
    border-radius: 6px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input:focus {
    outline: ${(props) =>
      props.themeColor
        ? `1px solid ${props.themeColor}`
        : "1px solid rgba(217, 217, 217, 1)"};
    border-radius: 6px;
  }

  @media (max-width: 800px) {
    .hero-logo {
      height: 35vh;
      display: ${(props) =>
        props.mobileCoverImageVisibility ? "flex" : "none"};
    }

    .login-wrapper {
      padding: 1.5rem;
      display: flex;
    }

    .login-section {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding: 30px;
      min-height: 65vh;
      max-height: ${(props) =>
        props.mobileCoverImageVisibility ? "85vh" : "100vh"};
      height: auto;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35);
      justify-content: flex-start;

      .brand-logo {
        display: ${(props) =>
          props.mobileBrandImageVisibility ? "block" : "none"};
        max-width: 100px;
      }

      .return-title {
        font-size: 18px;
        margin: 15px 0px 20px;
      }

      .input-label {
        font-size: 14px;
      }

      .brand-note {
        font-size: 12px;
      }
    }

    .error-screen-mob {
      display: block;
    }

    .error-screen-web {
      display: none;
    }

    .otp-input {
      margin-left: 10px;
      width: 40px;
      height: 40px;
      font-size: 1rem;
    }
  }
`;

export const StyledDrawerTnC = styled.div`
  padding: 20px;
  line-height: 1.5rem;
  max-height: 90vh;
  color: #333;
  font-size: 12px;
  overflow: hidden;

  ul,
  ol {
    padding-left: 20px;
  }
`;
