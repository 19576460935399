import { Button, Grid, Skeleton } from "@mui/material";
import CartProducts from "components/common/CartProducts/CartProducts";
import { PriceBox } from "components/common/PriceBox";
import { PickupAddress } from "components/Orders/Address";
import { getMerchantConfig, getRmsConfig } from "features/config/configSlice";
import {
  updateReturnOrder,
  updateSelectedOrder,
} from "features/orders/orderSlice";
import {
  addPreviews,
  addProceedBool,
  addProductType,
  addSelectedReason,
  addSelectedSubReason,
  addUploads,
  setImageUploads,
} from "features/product/productSlice";
import {
  addRefundJSON,
  addRefundSource,
  addReturnedProducts,
} from "features/returnedProducts/returnedProductSlice";
import { getReturnRequestId } from "features/successProducts/successProductsSlice";
import { getEmail, getPhone } from "features/user/userSlice";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../common/Header";
import { StyledExchange } from "../ReturnCart/styled";
import { ReactComponent as CheckCircle } from "assets/check-circle.svg";
import { selectedReturnOrderAPI } from "utils/api";

function Success() {
  const location = useLocation();
  const dispatch = useDispatch();
  const userPhone = useSelector(getPhone);
  const userEmail = useSelector(getEmail);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");

  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      setfinishStatus(true);
      let url = "/orders";
      if (encipherencode !== null && encipherencode !== undefined) {
        url += `?encipherencode=${encipherencode}`;
      }
      navigate(url);
    }
  };

  const merchantConfig = useSelector(getMerchantConfig);
  const StyledApp = styled.div`
    font-family: ${merchantConfig.google_font_family !== null
      ? merchantConfig.google_font_family
      : "Poppins,sans-serif"};
    text-align: center;
  `;
  const returnRequest = useSelector(getReturnRequestId);
  const [successProducts, setSuccessProducts] = useState(null);
  const [updatedAddress, setUpdatedAddress] = useState({});
  const [successLoader, setSuccessLoader] = useState(true);
  const rmsConfig = useSelector(getRmsConfig);

  useEffect(() => {
    if (userEmail == "" && userPhone == "") {
      let url = "/";
      if (encipherencode !== null && encipherencode !== undefined) {
        url += `?encipherencode=${encipherencode}`;
      }
      navigate(url);
    }
    window.history.pushState(
      null,
      null,
      encipherencode && encipherencode !== null
        ? `${window.location.pathname}?encipherencode=${encipherencode}`
        : window.location.pathname
    );
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    selectedReturnOrderAPI(
      merchantConfig.merchant_id,
      returnRequest.return_request_id,
      null,
      returnRequest.create_exchange_order
    ).then((resp) => {
      setSuccessProducts(resp.data);
      setUpdatedAddress({
        addressLine1: resp.data.customer_address_line_1,
        addressLine2: resp.data.customer_address_line_2,
        city: resp.data.customer_address_city,
        pincode: resp.data.customer_address_pin,
        state: resp.data.customer_address_state,
      });
      setSuccessLoader(false);
    });
    dispatch(updateSelectedOrder({}));
    dispatch(addSelectedReason(null));
    dispatch(addSelectedSubReason(null));
    dispatch(addUploads([]));
    dispatch(setImageUploads([]));
    dispatch(addPreviews([]));
    dispatch(addReturnedProducts([]));
    dispatch(addProductType(null));
    dispatch(addProceedBool(false));
    dispatch(addRefundJSON({}));
    dispatch(addRefundSource(null));
  }, []);

  const StyledSuccess = styled.div`
    padding: 100px 0px;

    .title {
      font-size: 1.5rem;
      margin: 16px;
      font-weight: 600;
      color: #1c1b1b;
    }

    .return-id {
      font-size: 1.25rem;
      margin: 16px;
      color: #333;
    }

    .info {
      font-size: 1rem;
      margin: 16px;
      color: #333;
    }

    @media (max-width: 800px) {
      .title {
        font-size: 16px;
        margin: 0px;
      }
      .return-id {
        font-size: 14px;
      }
      .info {
        font-size: 12px;
      }
    }
  `;

  const handleTracking = () => {
    dispatch(updateReturnOrder(successProducts.id));
    let url = "/tracking";
    if (encipherencode !== null && encipherencode !== undefined) {
      url += `?encipherencode=${encipherencode}`;
    }
    navigate(url);
  };

  return (
    <StyledApp>
      <Header />
      {successProducts !== null && !successLoader ? (
        <StyledSuccess>
          <p className="title">
            <CheckCircle style={{ marginRight: "5px" }} />
            Your
            {successProducts.return_type == "refund"
              ? " Return "
              : successProducts.return_type == "exchange"
              ? " Exchange "
              : " Return and Exchange "}{" "}
            Request Placed Successfully
          </p>
          <p className="return-id">
            Track your return request. Request ID{" "}
            <span
              style={{
                textDecoration: "underline",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={() => handleTracking()}
            >
              {" "}
              #{successProducts.reference_id}
            </span>
          </p>

          <StyledExchange
            location={location.pathname}
            style={{
              textAlign: "left",
              marginBottom: "40px",
              justifyContent: "space-between",
              border: "none",
            }}
          >
            <div className="products-return">
              <p
                className="product-details"
                style={{
                  fontWeight: "600",
                }}
              >
                Product Details
              </p>
              <Grid
                alignItems="center"
                container
                gridTemplateColumns="repeat(12, 1fr)"
                className="cart-products"
              >
                {successProducts.line_items !== undefined &&
                  successProducts.line_items.length > 0 &&
                  successProducts.line_items.map((product, i) => {
                    return (
                      <Grid
                        key={i}
                        justifyContent="center"
                        item
                        xs={12}
                        style={{
                          borderBottom: `1.5px solid rgba(17, 17, 17, 0.2)`,
                        }}
                      >
                        <CartProducts
                          product={product}
                          location={location.pathname}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </div>

            <div style={{ borderRight: "2px dashed #1111111A", flex: "0.1" }} />

            <div className="price-details">
              {/* <p className="select pricing" style={{ marginBottom: "20px" }}>
                Pricing & Shipping Details
              </p> */}

              <div className="box address">
                <PickupAddress
                  edit={false}
                  updatedAddress={updatedAddress}
                  serviceable={true}
                />
              </div>

              {(successProducts.return_type == "refund" ||
                successProducts.return_type == "refund_exchange") && (
                <PriceBox
                  infoType="Return"
                  returnItems={successProducts}
                  refundSource={
                    successProducts.customer_preferred_refund_method
                  }
                  returnType={successProducts.return_type}
                />
              )}

              {(successProducts.return_type == "exchange" ||
                successProducts.return_type == "refund_exchange") &&
                (successProducts.exchange_shipment_charge > 0 ||
                  successProducts.exchange_details.length > 0) && (
                  <PriceBox
                    infoType="Exchange"
                    returnItems={successProducts}
                    refundSource={
                      successProducts.customer_preferred_refund_method
                    }
                    returnType={successProducts.return_type}
                  />
                )}

              {/* {successProducts.line_items &&
                successProducts.return_shipment_charge > 0 && (
                  <PriceBox
                    infoType="Price"
                    returnItems={successProducts}
                    refundSource={
                      successProducts.customer_preferred_refund_method
                    }
                  />
                )} */}
            </div>
          </StyledExchange>

          <div>
            <Button
              color="primary"
              type="link"
              target="_blank"
              href={`https://${merchantConfig.domain}`}
              style={{
                display: "block",
                margin: "20px auto",
                width: "fit-content",
              }}
              variant="contained"
            >
              Explore Our special offers
            </Button>
            <Button
              color="primary"
              type="link"
              href={
                encipherencode && encipherencode !== null
                  ? `/orders?encipherencode=${encipherencode}`
                  : "/orders"
              }
              style={{ marginBottom: "20px" }}
              variant="text"
            >
              Return other products
            </Button>
          </div>
          {merchantConfig.whatsapp_help &&
            merchantConfig.whatsapp_help !== "" && (
              <a
                href={merchantConfig.whatsapp_help}
                target="_blank"
                rel="noreferrer"
                style={{ color: "initial", fontSize: "12px" }}
              >
                Contact Support
              </a>
            )}
        </StyledSuccess>
      ) : (
        <div style={{ padding: "100px" }}>
          <Skeleton style={{ width: "100%" }} height={50} />
          <div style={{ display: "flex" }}>
            <Skeleton height={100} style={{ width: "47.5%" }} />
            <Skeleton
              height={100}
              style={{ marginLeft: "100px", width: "47.5%" }}
            />
          </div>
        </div>
      )}
    </StyledApp>
  );
}

export default Success;
