import styled from "styled-components";

export const StyledOrderList = styled.div`
  padding: 100px 0% 100px 5.5%;
  display: flex;

  .order-skeleton {
    margin-top: 30px;
    width: 45%;
  }

  .customer-name {
    color: var(--color-primary);
    font-weight: 400;
    font-size: 1.25rem;
    margin: 10px 0px;
  }

  .order-wrapper {
    width: 50%;
    float: left;
    .return-container {
      width: 90%;
      .return-box {
        width: 48%;
        padding: 24px;
        margin: 10px 0px;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid rgba(17, 17, 17, 0.2);

        div {
          display: flex;
          align-items: center;
        }
        .return-reference-id {
          margin-right: 10px;
          padding: 4px;
          text-decoration: underline;
        }

        .return-status-label {
          background: #16ac374d;
          color: #333333;
          font-size: 12px;
          padding: 5px 10px;
          margin-top: -4px;
          white-space: nowrap;
          max-width: 175px;
          text-overflow: ellipsis;
          display: inline-block;
          overflow: hidden;
          border-radius: 4px;
        }

        .return-product-image {
          margin-right: 10px;
          height: 100%;
          max-height: 50px;
          border-radius: 4px;
          object-fit: cover;
        }
      }
    }
  }

  .return-for {
    color: #333333b2;
    font-size: 1rem;
    margin: 6px 0px;
  }

  .return-policy {
    margin: 15px 0px;
    color: #333333;
    font-size: 14px;
  }

  .selected {
    border: 1px solid #111111 !important;
    background: #f5f5f5;
  }

  @media (max-width: 800px) {
    padding: 70px 0px 0px;
    justify-content: center;

    .order-skeleton {
      margin-top: 10px;
      width: 90%;
    }

    .customer-name {
      font-size: 16px;
      margin: 0px;
      padding: 0 15px;
    }

    .order-wrapper {
      width: 100%;
      display: ${(props) =>
        props.selectedOrder !== undefined && props.selectedOrder.length > 0
          ? "none"
          : "block"};
      .return-container {
        width: 100%;
        .return-box {
          width: 100%;
          margin: 10px 15px 0px !important;
          background: #fff;
          .return-reference-id {
            font-size: 13px;
          }
        }
      }
    }

    .return-for {
      font-size: 12px;
      padding: 0 15px;
    }

    .return-policy {
      font-size: 12px;
      padding: 0 15px;
    }

    .more-orders,
    .help,
    .retry,
    .search-container,
    .search-field {
      padding: 0 15px;
    }
  }
`;

export const StyledOrderBox = styled.div`
  padding: 1.25rem 1rem;
  border-bottom: 1px solid rgba(17, 17, 17, 0.2);
  margin: 0px;
  width: 90%;
  background: ${(props) => (props.eligible ? "#fff" : "rgba(0,0,0,0.1)")};
  cursor: ${(props) => (props.eligible ? "pointer" : "not-allowed")};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  :hover {
    background: ${(props) =>
      props.eligible ? `${props.primary_color}11` : "rgba(0,0,0,0.1)"};

    .right-icon {
      color: ${(props) => props.primary_color};
      fill: ${(props) => props.primary_color};
    }
  }

  .right-icon {
    margin-left: auto;
    font-size: 24px;
  }
  .order-number {
    font-size: 1rem;
    color: #333333;
    text-decoration: underline;
  }

  .total-price {
    font-size: 1rem;
    color: #333333b2;
    margin-left: 4px;
  }

  @media (max-width: 800px) {
    margin: 0px;
    padding: 1rem;
    width: 100%;

    .order-number,
    .total-price,
    .fulfilment-status {
      font-size: 0.9rem;
    }
  }
`;

export const StyledAddress = styled.div`
  .address-skeleton {
    margin-top: 220px;
    width: 100%;
    min-width: 300px;
  }

  @media (min-width: 800px) {
    border-left: 1px dashed rgba(17, 17, 17, 0.2);
    padding: 0px 40px;
    width: 50%;
    float: right;
  }
`;
