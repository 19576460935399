import React, { Fragment, useEffect, useState } from "react";
import { StyledAddress, StyledOrderBox, StyledOrderList } from "./styled";
import Address from "../Address";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  deliveryStatusCheck,
  ordersList,
  returnRequestsAPI,
  serviceabiltyCheck,
} from "utils/api";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantConfig, getRmsConfig } from "features/config/configSlice";
import {
  addEmail,
  addPhone,
  getEmail,
  getPhone,
} from "features/user/userSlice";
import {
  addOrders,
  getSelectedOrder,
  updateReturnOrder,
  updateSelectedOrder,
} from "features/orders/orderSlice";
import { format } from "date-fns/esm";
import { Button, Skeleton, TextField, Typography } from "@mui/material";
import { ReactComponent as ErrorIcon } from "assets/error.svg";
import {
  addRefundJSON,
  addRefundSource,
  addReturnedProducts,
} from "features/returnedProducts/returnedProductSlice";
import {
  addAddress,
  addShipmentMethod,
  updateServiceCheck,
  updateServiceability,
  updateTempServiceability,
} from "features/address/addresSlice";
import { ReactComponent as ChevronRightIcon } from "assets/chevron-right-round.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

function OrdersList({ setLoginLoader }) {
  // Variables
  const [params, setParams] = useSearchParams();
  const [moreOrders, setMoreOrders] = useState(false);
  const [allOrders, setAllOrders] = useState({});
  const [returnRequests, setReturnRequests] = useState([]);
  const [currentRequests, setCurrentRequests] = useState([]);
  const merchantConfig = useSelector(getMerchantConfig);
  const rmsConfig = useSelector(getRmsConfig);
  const userPhone = useSelector(getPhone) || params.get("p");
  const userEmail = useSelector(getEmail) || params.get("e");
  const dispatch = useDispatch();
  const selectedOrder = useSelector(getSelectedOrder);
  const [customerName, setCustomerName] = useState("");
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [addressLoader, setAddressLoader] = useState(true);
  const [serviceability, setserviceability] = useState(false);
  const [serviceableCheck, setServiceableCheck] = useState(false);
  const [pincode, setPincode] = useState(null);
  const [serviceableLoader, setServiceableLoader] = useState(false);
  const navigate = useNavigate();
  const [offSet, setOffSet] = useState(0);
  const [apiLoadError, setApiLoadError] = useState(false);
  const [requestClick, setRequestClick] = useState(0);
  const [noMoreRequests, setNoMoreRequests] = useState(0);
  const [viewMoreLoading, setViewMoreLoading] = useState(false);
  const [addressApiLoader, setAddressApiLoader] = useState(false);
  const [tempServiceablity, setTempServiceabilty] = useState(serviceableCheck);
  const [deliveryStatus, setDeliveryStatus] = useState(false);
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");
  const [finishStatus, setfinishStatus] = useState(false);
  const [orderNameInputSearch, setOrderNameInputSearch] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchOrderData, setSearchOrderData] = useState([]);
  const [searchingOrder, setSearchingOrder] = useState(false);
  const orderId = params.get("oid");
  const checkOrderServiceability = params.get("cs");
  toast.configure();

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      setfinishStatus(true);
      if (selectedOrder !== null && Object.keys(selectedOrder).length > 0) {
        dispatch(updateSelectedOrder({}));
      } else {
        let url = "/";
        if (encipherencode !== null && encipherencode !== undefined) {
          url += `?encipherencode=${encipherencode}`;
        }
        navigate(url);
        let iframe = document.getElementById("sessionIframe");
        iframe.contentWindow.postMessage(
          { phone: null, email: null, topic: "PRAGMA" },
          "*"
        );
        dispatch(addPhone(null));
        dispatch(addEmail(null));
      }
    }
  };

  // Calling APIs on page load
  useEffect(() => {
    if (
      Object.keys(merchantConfig).length > 0 &&
      (userPhone !== null || userEmail !== null)
    ) {
      ordersList(merchantConfig.merchant_id, userPhone, userEmail, offSet)
        .then((resp) => {
          setAllOrders(resp.data.orders);
          dispatch(addOrders(resp.data.orders));
          setCustomerName(resp.data.customer_name);
          setOrdersLoading(false);
          setApiLoadError(false);
          setLoginLoader(false);
          if (orderId !== null && checkOrderServiceability) {
            if (userPhone != null) {
              dispatch(addPhone(userPhone));
            }
            if (userEmail !== null) {
              dispatch(addEmail(userEmail.toLowerCase()));
            }
            let preSelectedOrder = resp.data.orders.filter(
              (a) => a.order_id == orderId
            )[0];
            if (preSelectedOrder && preSelectedOrder !== null) {
              if (preSelectedOrder.eligible) {
                updateOrderDetails(preSelectedOrder);
              } else {
                toast.error("Order is not eligible for refund / exchange", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 6000,
                  hideProgressBar: false,
                  closeOnClick: true,
                });
              }
            } else {
              toast.error("No order found. Please check the Order Id again.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
              });
            }
          } else {
            dispatch(updateSelectedOrder({}));
          }
        })
        .catch((err) => {
          if (userPhone === "" && userEmail === "") {
            let iframe = document.getElementById("sessionIframe");
            iframe.contentWindow.postMessage(
              { phone: null, email: null, topic: "PRAGMA" },
              "*"
            );
            let url = "/";
            if (encipherencode !== null && encipherencode !== undefined) {
              url += `?encipherencode=${encipherencode}`;
            }
            navigate(url);
            dispatch(addPhone(null));
            dispatch(addEmail(null));
          } else {
            // setApiLoadError(true);
            setOrdersLoading(false);
          }
        });
      returnRequestsAPI(
        merchantConfig.merchant_id,
        userPhone,
        userEmail,
        offSet
      )
        .then((resp) => {
          setReturnRequests(resp.data);
          setCurrentRequests(resp.data.slice(0, 2));
          setApiLoadError(false);
        })
        .catch((err) => {
          if (userPhone === "" && userEmail === "") {
            let iframe = document.getElementById("sessionIframe");
            iframe.contentWindow.postMessage(
              { phone: null, email: null, topic: "PRAGMA" },
              "*"
            );
            let url = "/";
            if (encipherencode !== null && encipherencode !== undefined) {
              url += `?encipherencode=${encipherencode}`;
            }
            navigate(url);
            dispatch(addPhone(null));
            dispatch(addEmail(null));
          } else {
            // setApiLoadError(true);
            setOrdersLoading(false);
          }
        });
      window.history.pushState(
        null,
        null,
        encipherencode && encipherencode !== null
          ? `${window.location.pathname}?encipherencode=${encipherencode}`
          : `${window.location.pathname}`
      );
      window.addEventListener("popstate", onBackButtonEvent);
      return () => {
        window.removeEventListener("popstate", onBackButtonEvent);
      };
    }
    dispatch(addRefundSource(null));
    dispatch(addRefundJSON({}));
  }, [merchantConfig]);

  useEffect(() => {
    dispatch(addReturnedProducts([]));
  }, []);

  // Handle View more of return requests
  const handleViewMore = () => {
    setRequestClick(requestClick + 1);
    setViewMoreLoading(true);
    if (requestClick > 0) {
      returnRequestsAPI(
        merchantConfig.merchant_id,
        userPhone,
        userEmail,
        offSet + 10
      )
        .then((resp) => {
          // setReturnRequests(resp.data);
          if (resp.data.length > 0) {
            setCurrentRequests(currentRequests.concat(resp.data));
            setOffSet(offSet + 10);
            setViewMoreLoading(false);
          } else {
            setNoMoreRequests(true);
            setViewMoreLoading(false);
          }
        })
        .catch((err) => {
          if (userPhone === "" && userEmail === "") {
            let url = "/";
            if (encipherencode !== null && encipherencode !== undefined) {
              url += `?encipherencode=${encipherencode}`;
            }
            navigate(url);
          }
        });
    } else {
      setCurrentRequests(returnRequests);
      setViewMoreLoading(false);
    }
  };

  // Serviceabilty API Call
  const checkServiceabilty = (pin, edit) => {
    serviceabiltyCheck(pin, merchantConfig.merchant_id)
      .then((resp) => {
        setserviceability(resp.data.serviceable);
        dispatch(updateServiceability(resp.data.serviceable));
        if (edit) {
          setTempServiceabilty(resp.data.serviceable);
          dispatch(updateTempServiceability(resp.data.serviceable));
        } else {
          setServiceableCheck(resp.data.serviceable);
          dispatch(updateServiceCheck(resp.data.serviceable));
        }
        setAddressLoader(false);
        setServiceableLoader(false);
        setAddressApiLoader(false);
        if (resp.data.serviceable) {
          dispatch(addShipmentMethod("merchant_handles_return_shipment"));
        } else {
          dispatch(addShipmentMethod("customer_ships_back"));
        }
      })
      .catch((err) => {
        setAddressApiLoader(true);
      });
  };

  // Updating the selected Order details
  const updateOrderDetails = (order) => {
    dispatch(updateSelectedOrder(order));
    dispatch(
      addAddress({
        pincode: order.shipping_address.zip || order.billing_address.zip,
        firstName: order.customer_first_name,
        lastName: order.customer_last_name,
        addressLine1:
          order.shipping_address.address1 || order.billing_address.address1,
        addressLine2:
          order.shipping_address.address2 || order.billing_address.address2,
        state:
          order.shipping_address.province || order.billing_address.province,
        city: order.shipping_address.city || order.billing_address.city,
        country:
          order.shipping_address.country || order.billing_address.country,
      })
    );
    setPincode(order.shipping_address.zip || order.billing_address.zip);
    window.scrollTo(0, 0);
    if (order.delivered_at !== null) {
      setDeliveryStatus(true);
      if (rmsConfig.supports_return_pickup) {
        setAddressLoader(true);
        checkServiceabilty(
          order.shipping_address.zip || order.billing_address.zip
        );
      } else {
        setserviceability(true);
        dispatch(updateServiceability(true));
        setAddressLoader(false);
        setServiceableCheck(false);
        dispatch(updateServiceCheck(false));
        setAddressApiLoader(false);
      }
      let url = "/products";
      if (encipherencode !== null && encipherencode !== undefined) {
        url += `?encipherencode=${encipherencode}`;
      }
      navigate(url);
    } else {
      setAddressLoader(true);
      deliveryStatusCheck(merchantConfig.merchant_id, order.order_id)
        .then((resp) => {
          setDeliveryStatus(true);
          if (rmsConfig.supports_return_pickup) {
            setAddressLoader(true);
            checkServiceabilty(
              order.shipping_address.zip || order.billing_address.zip
            );
          } else {
            setserviceability(true);
            dispatch(updateServiceability(true));
            setAddressLoader(false);
            setServiceableCheck(false);
            dispatch(updateServiceCheck(false));
            setAddressApiLoader(false);
          }
          dispatch(updateSelectedOrder(resp.data.orders[0]));
          let url = "/products";
          if (encipherencode !== null && encipherencode !== undefined) {
            url += `?encipherencode=${encipherencode}`;
          }
          navigate(url);
        })
        .catch((err) => {
          setDeliveryStatus(false);
          setAddressLoader(false);
          toast.error("The order has not been delivered yet.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        });
    }
  };

  // Order Box Strucure
  const OrderBox = ({ order }) => {
    return (
      <StyledOrderBox
        className={
          selectedOrder !== null &&
          selectedOrder.order_number === order.order_number &&
          order.eligible
            ? "selected"
            : ""
        }
        primary_color={merchantConfig.primary_theme_color}
        eligible={order.eligible}
        onClick={() => {
          if (order.eligible) {
            updateOrderDetails(order);
          }
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="order-number">{order.order_name}</span>
          {/* <span className="total-price"> (₹ {order.total_order_price})</span> */}
          {order.fulfillment_status !== null && (
            <span className="fulfilment-status">
              <span
                style={{
                  background:
                    order.fulfillment_status === "Delivered"
                      ? "#16ac374d"
                      : "#fff8dd",
                  color: "#333333",
                  fontSize: "10px",
                  padding: "3px 10px",
                  marginLeft: "10px",
                  borderRadius: merchantConfig.use_rounded_edge_buttons
                    ? "4px"
                    : "0px",
                }}
              >
                {order.fulfillment_status}
              </span>
              {order.delivered_at !== null && (
                <span
                  style={{
                    fontSize: "12px",
                    color: "#333333B2",
                    marginLeft: "10px",
                  }}
                >
                  {format(new Date(order.delivered_at), "dd MMM, yyyy")}
                </span>
              )}
            </span>
          )}
          {order.eligible && <KeyboardArrowRightIcon className="right-icon" />}
        </div>
        <div style={{ display: "flex", marginTop: "12px", overflow: "hidden" }}>
          {order.order_items &&
            order.order_items.map((product, i) => {
              return (
                <img
                  key={i}
                  src={product.image_url}
                  alt="preview"
                  style={{
                    marginRight: "10px",
                    height: "100%",
                    maxHeight: "50px",
                    borderRadius: "4px",
                    objectFit: "cover",
                  }}
                />
              );
            })}
        </div>
      </StyledOrderBox>
    );
  };

  const handleTracking = (id, reference_id) => {
    dispatch(updateReturnOrder(id));
    let url = "/tracking";
    if (encipherencode !== null && encipherencode !== undefined) {
      url += `?encipherencode=${encipherencode}`;
    }
    navigate(url);
  };

  // Return Box Strucure
  const ReturnBox = ({ returnItem }) => {
    return (
      <div
        className="return-box"
        onClick={() => {
          handleTracking(returnItem.id, returnItem.reference_id);
          dispatch(updateSelectedOrder(returnItem));
        }}
      >
        <div>
          <p className="return-reference-id">#{returnItem.reference_id}</p>
          <p className="return-status-label">{returnItem.status_label}</p>
        </div>
        <div style={{ marginTop: "10px", overflow: "hidden" }}>
          {returnItem.line_items.map((product, i) => {
            return (
              <img
                className="return-product-image"
                src={product.variant_image_url}
                alt="preview"
                key={i}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const SkeletonGridBox = () => {
    return (
      <div
        style={{
          marginTop: "20px",
          border: "1px solid  rgba(17, 17, 17, 0.2)",
          padding: "24px",
        }}
      >
        <Typography variant="h4" sx={{ bgColor: "black" }}>
          <Skeleton />
        </Typography>
        <div style={{ display: "flex" }}>
          <Skeleton variant="rounded" height={50} width={50} />
          <Skeleton
            variant="rounded"
            height={50}
            width={50}
            style={{ marginLeft: "10px" }}
            sx={{ bgColor: "black" }}
          />
          <Skeleton
            variant="rounded"
            height={50}
            width={50}
            style={{ marginLeft: "10px" }}
            sx={{ bgColor: "black" }}
          />
        </div>
      </div>
    );
  };

  // Skeleton Loader
  const LoaderGrid = () => {
    return (
      <div className="order-skeleton">
        <Typography variant="h4" sx={{ bgColor: "black" }}>
          <Skeleton />
        </Typography>

        <SkeletonGridBox />
        <SkeletonGridBox />
        <SkeletonGridBox />
      </div>
    );
  };

  return (
    <StyledOrderList
      selectedOrder={
        selectedOrder !== undefined &&
        selectedOrder !== null &&
        selectedOrder.order_number
      }
      primary_color={merchantConfig.primary_theme_color}
    >
      {apiLoadError ? (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "80vh",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <ErrorIcon />
          <p
            style={{
              marginTop: "25px",
              color: "#1d8dcc",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={() => window.location.reload()}
          >
            Reload
          </p>
        </div>
      ) : ordersLoading ? (
        <LoaderGrid />
      ) : allOrders.length > 0 ? (
        <Fragment>
          <div className="order-wrapper">
            <p className="customer-name">
              <span style={{ fontWeight: "500" }}> Hey {customerName}</span>{" "}
              {returnRequests.length > 0 &&
                `, we found ${returnRequests.length} existing requests`}{" "}
            </p>

            <div
              className="return-container"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {currentRequests.map((returnItem) => {
                return (
                  <ReturnBox returnItem={returnItem} key={returnItem.id} />
                );
              })}
            </div>

            {returnRequests.length > 2 && !noMoreRequests ? (
              <p
                className="more-orders"
                onClick={() => handleViewMore()}
                style={{
                  textDecoration: "underline",
                  color: "var(--color-primary)",
                  fontSize: "14px",
                  cursor: "pointer",
                  width: "fit-content",
                  marginTop: "10px",
                }}
              >
                View more requests
              </p>
            ) : (
              ""
            )}

            {viewMoreLoading && <LoaderGrid />}

            {allOrders.filter((a) => a.eligible).length > 0 ? (
              <p className="return-for">
                {" "}
                {returnRequests.length > 0 && "Or"} Select an order you want to
                {rmsConfig.supports_exchange && rmsConfig.supports_refund
                  ? " return or exchange"
                  : rmsConfig.supports_exchange
                  ? " exchange"
                  : " return"}{" "}
                for
              </p>
            ) : (
              <p className="return-for">Oops! No eligible orders found.</p>
            )}

            {allOrders && (
              <Fragment>
                {allOrders
                  .filter((a) => a.eligible)
                  .map((order, i) => {
                    return <OrderBox key={i} order={order} />;
                  })}

                <p
                  className="more-orders"
                  onClick={() => setMoreOrders(!moreOrders)}
                  style={{
                    textDecoration: "underline",
                    color: "var(--color-primary)",
                    fontSize: "14px",
                    cursor: "pointer",
                    width: "fit-content",
                    marginTop: "1rem",
                  }}
                >
                  {!moreOrders ? "View previous orders" : "Show Less"}
                </p>

                {moreOrders && (
                  <div style={{ marginTop: "10px" }}>
                    <p className="return-policy">
                      These orders are ineligible for return or exchange. Our
                      return policy lasts{" "}
                      {rmsConfig.days_to_raise_request_threshold} days.
                    </p>
                    {allOrders
                      .filter((a) => !a.eligible)
                      .map((order, i) => {
                        return <OrderBox key={i} order={order} />;
                      })}
                    <p
                      className="retry"
                      style={{
                        color: "var(--color-primary)",
                        margin: "25px 0px 0px",
                        fontSize: "14px",
                      }}
                    >
                      Still didn't find the order you were looking for? Try{" "}
                      <span
                        style={{
                          fontWeight: "500",
                          color: "inherit",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          dispatch(addEmail(null));
                          dispatch(addPhone(null));
                          let iframe = document.getElementById("sessionIframe");
                          iframe.contentWindow.postMessage(
                            { phone: null, email: null, topic: "PRAGMA" },
                            "*"
                          );
                          let url = "/";
                          if (
                            encipherencode !== null &&
                            encipherencode !== undefined
                          ) {
                            url += `?encipherencode=${encipherencode}`;
                          }
                          navigate(url);
                        }}
                      >
                        {" "}
                        Using different phone/email
                      </span>
                    </p>
                    <div className="search-container" style={{ fontSize: "14px", marginTop: "5px" }}>
                      <span style={{ marginLeft: "20px" }}>Or</span>
                      <p style={{ marginTop: "5px" }}>
                        Search here with{" "}
                        <span style={{ fontWeight: "600" }}>Order Name.</span>
                      </p>
                    </div>
                    <div className="search-field" style={{ display: "flex", marginTop: "10px" }}>
                      <TextField
                        name="Enter order name"
                        label="Order name"
                        value={orderNameInputSearch}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setOrderNameInputSearch(inputValue);
                        }}
                      />
                      <Button
                        variant="contained"
                        className="search"
                        style={{ display: "flex", margin: "0px 10px" }}
                        disabled={searchingOrder}
                        endIcon={
                          searchingOrder ? (
                            <BeatLoader
                              size={5}
                              color={merchantConfig.secondary_theme_color}
                            />
                          ) : (
                            ""
                          )
                        }
                        onClick={() => {
                          setSearchingOrder(true);
                          ordersList(
                            merchantConfig.merchant_id,
                            userPhone,
                            userEmail,
                            offSet,
                            orderNameInputSearch
                          ).then((resp) => {
                            setShowSearchResults(true);
                            if (resp.data.orders.length > 0) {
                              setSearchOrderData(resp.data.orders);
                            } else {
                              setSearchOrderData([]);
                            }
                            setSearchingOrder(false);
                            setTimeout(() => {
                              document
                                .getElementById("searchOutput")
                                .scrollIntoView();
                            }, 100);
                          });
                        }}
                      >
                        {searchingOrder ? "Searching " : "Search"}
                      </Button>
                    </div>
                    {searchingOrder ? (
                      <SkeletonGridBox />
                    ) : (
                      showSearchResults && (
                        <div id="searchOutput" style={{ marginTop: "10px" }}>
                          {searchOrderData.length > 0 ? (
                            searchOrderData.map((order) => {
                              return <OrderBox order={order} />;
                            })
                          ) : (
                            <p>
                              Sorry we couldn't find any order with that name
                            </p>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}

                <p onClick={() => navigate("/")} className="change-user">
                  Change Phone/Email ?
                </p>

                {merchantConfig.whatsapp_help &&
                  merchantConfig.whatsapp_help !== null && (
                    <a
                      href={merchantConfig.whatsapp_help}
                      target="_blank"
                      rel="noreferrer"
                      className="help"
                      style={{
                        fontWeight: "600",
                        fontSize: "12px",
                        color: "var(--color-primary)",
                        margin: "30px 0px",
                        display: "block",
                      }}
                    >
                      Need Help?
                    </a>
                  )}
              </Fragment>
            )}
          </div>

          {/* Address Section  */}

          {selectedOrder !== null &&
            allOrders &&
            allOrders.filter(
              (a) => a.order_number === selectedOrder.order_number
            ).length > 0 && (
              <StyledAddress>
                <Address
                  allOrders={allOrders}
                  selectedOrder={selectedOrder.order_number}
                  addressLoader={addressLoader}
                  serviceableCheck={serviceableCheck}
                  setServiceableCheck={setServiceableCheck}
                  serviceability={serviceability}
                  checkServiceabilty={checkServiceabilty}
                  pincode={pincode}
                  setPincode={setPincode}
                  serviceableLoader={serviceableLoader}
                  setServiceableLoader={setServiceableLoader}
                  addressApiLoader={addressApiLoader}
                  tempServiceablity={tempServiceablity}
                  setTempServiceabilty={setTempServiceabilty}
                  deliveryStatus={deliveryStatus}
                />
              </StyledAddress>
            )}
        </Fragment>
      ) : (
        <div
          style={{
            color: "#333",
            textAlign: "center",
            width: "100%",
            marginTop: "5%",
          }}
        >
          <p style={{ lineHeight: "1.5rem" }}>
            We couldn't find any orders for {userPhone || userEmail}.{" "}
            <a
              href={
                encipherencode && encipherencode !== null
                  ? `/?encipherencode=${encipherencode}`
                  : "/"
              }
              style={{
                textDecoration: "underline",
                fontWeight: "600",
                color: "inherit",
              }}
              onClick={() => {
                dispatch(addEmail(null));
                dispatch(addPhone(null));
                let iframe = document.getElementById("sessionIframe");
                iframe.contentWindow.postMessage(
                  { phone: null, email: null, topic: "PRAGMA" },
                  "*"
                );
                let url = "/orders";
                if (encipherencode !== null && encipherencode !== undefined) {
                  url += `?encipherencode=${encipherencode}`;
                }
                navigate(url);
              }}
            >
              Change Email/Phone
            </a>
          </p>
          {merchantConfig.whatsapp_help &&
            merchantConfig.whatsapp_help !== null && (
              <a
                href={merchantConfig.whatsapp_help}
                target="_blank"
                rel="noreferrer"
                style={{ marginTop: "30px", display: "block" }}
              >
                Need Help?
              </a>
            )}
        </div>
      )}
    </StyledOrderList>
  );
}

export default OrdersList;
