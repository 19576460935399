import styled, { keyframes } from "styled-components";

const animation = keyframes`
    0%{
        opacity: 0;
        transform: translateY(25%);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 1;
        transform: trabnslateY(0);
    }
`;
export const StyledAddressChange = styled.div`
  height: 70vh;
  overflow-y: auto;
  position: absolute;
  top: 0px;
  z-index: 9;
  background: #fff;
  width: 85%;
  animation: ${animation} 0.75s linear;
  border: 1px solid rgba(17, 17, 17, 0.2);

  @media (max-width: 800px) {
    top: 0px;
    width: 100%;
    form {
      padding: 0px 20px 10px !important;
      background: #fff;
      margin: 10px 0px !important;
    }
  }
`;

export const StyledWeb = styled.div`
  display: none;
  @media (min-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
`;

export const StyledMob = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  @media (min-width: 800px) {
    display: none;
  }
`;

export const StyledPickup = styled.div`
  .pickup-address,
  .delivery-address {
    font-size: 14px;
    color: #333333b2;
    margin-top: 10px;
  }

  .pickup-address {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .change {
    font-size: 14px;
    color: #333333b2;
  }
  .pickup {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    span {
      font-weight: 600;
    }
  }
  .ship-back {
    color: #333;
    font-size: 18px;
    margin-top: 10px;
  }
  @media (max-width: 800px) {
    background: #fff;
    margin-bottom: 10px;

    .pickup {
      margin-bottom: 0rem;
      span {
        font-weight: 600;
        text-decoration: none;
      }
    }
    .pickup,
    .ship-back {
      font-size: 14px;
    }

    .change {
      font-size: 12px;
      margin-top: 0px;
    }

    .pickup-address,
    .delivery-address {
      font-size: 12px;
      margin-top: 5px;
    }
  }
`;

export const StyledShipping = styled.div`
  margin: 0px 0px 20px;
  @media (max-width: 800px) {
    .preference {
      font-size: 14px !important;
    }

    .address {
      font-size: 12px !important;
    }
  }
`;
