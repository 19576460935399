import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Grid, SwipeableDrawer, TextField } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { StyledDrawerTnC, StyledLogin } from "./styled";
import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import styled from "styled-components";
import Otp from "./Otp";
import { sendOTPRequest } from "utils/api";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantConfig, getRmsConfig } from "features/config/configSlice";
import { addEmail, addPhone } from "features/user/userSlice";
import parse from "html-react-parser";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { BeatLoader, ClipLoader } from "react-spinners";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "components/common/Footer";

function Login({ loginLoader, setLoginLoader }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [tnc, setTnc] = useState(false);
  const [confirmOtp, setConfirmOtp] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [response, setResponse] = useState(null);
  const merchantConfig = useSelector(getMerchantConfig);
  const dispatch = useDispatch();
  const rmsConfig = useSelector(getRmsConfig);
  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");
  const navigate = useNavigate();
  const [otpSendError, setOtpSendError] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const userPhone = params.get("p");
  const userEmail = params.get("e");
  const cs = params.get("cs");
  const orderid = params.get("oid");

  const navigateToOrders = useCallback(() => {
    let url = "/orders";
    if (encipherencode) {
      url += `?encipherencode=${encipherencode}`;
    }
    if (cs && orderid !== null) {
      url += `&cs=${cs}&oid=${orderid}`;
    }
    navigate(url);
  }, [navigate, encipherencode, cs, orderid]);

  useEffect(() => {
    dispatch(addPhone(null));
    dispatch(addEmail(null));
    const handleMessage = (event) => {
      const userObject = event.data;
      if (userObject && userObject.topic === "PRAGMA") {
        setLoginLoader(true);
        const { phone, email } = userObject.userInfo;
        if ((phone && phone.trim()) || (email && email.trim())) {
          dispatch(addPhone(phone));
          dispatch(addEmail(email));
          navigateToOrders();
        } else {
          setTimeout(() => {
            setLoginLoader(false);
          }, 750);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    if ((userPhone && userPhone.trim()) || (userEmail && userEmail.trim())) {
      dispatch(addPhone(userPhone));
      dispatch(addEmail(userEmail));
      navigateToOrders();
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [cs, orderid, userPhone, userEmail, dispatch, navigateToOrders]);

  const loginHandler = ({ email }) => {
    const editedValue = email.replace(/\s/g, "");
    if (rmsConfig.otp_verification_method === "no_verification") {
      if (!isNaN(+editedValue)) {
        dispatch(addPhone(editedValue));
      } else {
        dispatch(addEmail(editedValue.toLowerCase()));
      }
      let url = "/orders";
      if (encipherencode && encipherencode !== null) {
        url += `?encipherencode=${encipherencode}`;
      }
      navigate(url);
    } else {
      setOtpLoading(true);
      sendOTPRequest(
        !isNaN(+editedValue) ? null : editedValue.toLowerCase(),
        merchantConfig.merchant_id,
        !isNaN(+editedValue) ? editedValue.toLowerCase() : null
      )
        .then((resp) => {
          setResponse(resp.data);
          if (!isNaN(+editedValue)) {
            dispatch(addPhone(editedValue));
          } else {
            dispatch(addEmail(editedValue.toLowerCase()));
          }
          setConfirmOtp(true);
        })
        .catch((err) => {
          setOtpSendError(true);
        })
        .finally(() => {
          setOtpLoading(false);
        });
      setInputValue(editedValue);
    }
  };

  const StyledApp = styled.div`
    font-family: ${merchantConfig.google_font_family !== null
      ? merchantConfig.google_font_family
      : "Poppins,sans-serif"};
  `;

  const memoizedBrandImage = useMemo(() => {
    if (merchantConfig.brand_cover_image) {
      return (
        <img
          src={merchantConfig.brand_cover_image}
          alt={merchantConfig.shop_display_name}
          style={{ width: "100%" }}
        />
      );
    }
    return null;
  }, [merchantConfig.brand_cover_image, merchantConfig.shop_display_name]);

  const [isExpanded, setIsExpanded] = useState(false);
  const ReadMore = ({ content, maxLength = 500 }) => {
    const toggleReadMore = () => setIsExpanded(!isExpanded);

    // Check if the content exceeds the max length
    const contentString = content.toString(); // Convert to string if it's an HTML or object
    const isLongContent = contentString.length > maxLength;

    return (
      <div>
        {isExpanded
          ? parse(content) // Show full content
          : parse(
              contentString.substring(0, maxLength) +
                (isLongContent ? "..." : "")
            )}
        {isLongContent && (
          <button
            onClick={toggleReadMore}
            style={{
              background: "none",
              border: "none",
              color: "#1d8dcc",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            {isExpanded ? "Read Less" : "Read More"}
          </button>
        )}
      </div>
    );
  };

  return (
    <StyledApp>
      {loginLoader ? (
        <div
          style={{
            height: "100vh",
            background: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-30px",
          }}
        >
          <ClipLoader
            color="#333"
            size={50}
            speedMultiplier={0.75}
            cssOverride={{ borderWidth: "6px" }}
          />
        </div>
      ) : (
        <StyledLogin
          themeColor={merchantConfig.primary_theme_color}
          coverImage={merchantConfig.brand_cover_image}
          desktopBrandImageVisibility={
            merchantConfig?.public_page_theme_configurations
              ?.login_section_brand_logo_visible_in_desktop
          }
          mobileBrandImageVisibility={
            merchantConfig?.public_page_theme_configurations
              ?.login_section_brand_logo_visible_in_mobile
          }
          desktopCoverImageVisibility={
            merchantConfig?.public_page_theme_configurations
              ?.cover_image_visible_in_desktop
          }
          mobileCoverImageVisibility={
            merchantConfig?.public_page_theme_configurations
              ?.cover_image_visible_in_mobile
          }
        >
          {merchantConfig?.public_page_theme_configurations
            ?.cover_image_width === 50 ? (
            <Grid
              container
              rowSpacing={0}
              gridTemplateColumns="repeat(12, 1fr)"
              height="100%"
              columnSpacing={{ xs: 0, md: 4 }}
              direction={
                merchantConfig?.public_page_theme_configurations
                  .login_section_position === "right"
                  ? "row"
                  : "row-reverse"
              }
              justifyContent={
                !merchantConfig?.public_page_theme_configurations
                  ?.login_section_brand_logo_visible_in_desktop
                  ? merchantConfig?.public_page_theme_configurations
                      .login_section_position === "left"
                    ? "flex-start"
                    : merchantConfig?.public_page_theme_configurations
                        ?.login_section_position === "right"
                    ? "flex-end"
                    : "center"
                  : "center"
              }
            >
              {(merchantConfig?.public_page_theme_configurations
                .cover_image_visible_in_mobile ||
                merchantConfig?.public_page_theme_configurations
                  .cover_image_visible_in_desktop) && (
                <Grid className="hero-logo" item xs={12} md={6}>
                  {memoizedBrandImage}
                </Grid>
              )}
              {confirmOtp ? (
                <Otp
                  inputValue={inputValue}
                  setConfirmOtp={setConfirmOtp}
                  response={response}
                  setResponse={setResponse}
                />
              ) : !tnc ? (
                <Grid
                  className="login-section"
                  item
                  xs={12}
                  md={
                    merchantConfig?.public_page_theme_configurations
                      ?.cover_image_visible_in_desktop
                      ? 6
                      : 12
                  }
                  style={{
                    alignItems: merchantConfig?.public_page_theme_configurations
                      ?.cover_image_visible_in_desktop
                      ? "center"
                      : merchantConfig?.public_page_theme_configurations
                          ?.login_section_position === "left"
                      ? "flex-start"
                      : merchantConfig?.public_page_theme_configurations
                          ?.login_section_position === "right"
                      ? "flex-end"
                      : "center",
                  }}
                >
                  {(merchantConfig?.public_page_theme_configurations
                    ?.login_section_brand_logo_visible_in_desktop ||
                    merchantConfig?.public_page_theme_configurations
                      ?.login_section_brand_logo_visible_in_mobile) && (
                    <div style={{ maxWidth: "450px", width: "100%" }}>
                      <img
                        src={merchantConfig.brand_logo}
                        className="brand-logo"
                      />
                    </div>
                  )}
                  <p
                    className="return-title"
                    style={{
                      maxWidth: "450px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {rmsConfig.home_page_request_creation_title}
                  </p>
                  <form
                    onSubmit={handleSubmit(loginHandler)}
                    style={{ maxWidth: "450px", width: "100%" }}
                  >
                    <label htmlFor="email" style={{ display: "block" }}>
                      <p className="input-label">Email / Phone</p>
                      <Controller
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true,
                          pattern:
                            /^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}|[0-9]{10})?$/,
                        }}
                        name="email"
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="email"
                            error={Boolean(errors.email)}
                            helperText={
                              errors.email
                                ? errors.email.type === "pattern"
                                  ? "Invalid email/phone. Please check entered information."
                                  : "Email / Phone is required"
                                : "Enter Email/Phone used for placing the order with us"
                            }
                            inputProps={{
                              placeholder: "9876543210/john@gmail.com",
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius:
                                  merchantConfig.use_rounded_edge_buttons
                                    ? "4px"
                                    : "0px",
                              },
                              "& .MuiOutlinedInput-root input:focus": {
                                borderRadius:
                                  merchantConfig.use_rounded_edge_buttons
                                    ? "4px"
                                    : "0px",
                              },
                            }}
                            {...field}
                          />
                        )}
                      />
                    </label>
                    {otpSendError && (
                      <p
                        style={{
                          color: "rgba(221, 67, 93, 1)",
                          fontSize: "12px",
                        }}
                      >
                        Hmmm .... Can you please try again ?
                      </p>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      endIcon={otpLoading ? "" : <ArrowForwardIcon />}
                      className="otp-action"
                      color="primary"
                      style={{
                        width: "100%",
                        borderRadius: !merchantConfig.use_rounded_edge_buttons
                          ? "0px"
                          : "4px",
                        padding: "10px",
                        fontSize: "18px",
                        fontWeight: "600",
                        marginTop: "30px",
                      }}
                    >
                      {otpLoading ? (
                        <BeatLoader
                          color={merchantConfig.secondary_theme_color}
                        />
                      ) : (
                        "Proceed"
                      )}
                    </Button>
                    {rmsConfig.brand_home_page_note &&
                      rmsConfig.brand_home_page_note !== null &&
                      rmsConfig.brand_home_page_note !== "" && (
                        <p className="brand-note">
                          <ReadMore
                            content={rmsConfig.brand_home_page_note}
                            maxLength={490}
                          />
                        </p>
                      )}
                    <p
                      style={{
                        margin: "20px auto",
                        textAlign: "center",
                        color: "#000000B2",
                        fontSize: "12px",
                      }}
                    >
                      By proceeding, you accept our{" "}
                      <span
                        onClick={() => setTnc(true)}
                        style={{
                          color: "#1d8dcc",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Terms & Conditions
                      </span>
                    </p>
                    {merchantConfig.whatsapp_help &&
                      merchantConfig.whatsapp_help !== null && (
                        <a
                          href={merchantConfig.whatsapp_help}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textAlign: "center",
                            display: "block",
                            margin: "30px auto 10px",
                            color: "#000000B2",
                            fontSize: "12px",
                          }}
                        >
                          Need Help?
                        </a>
                      )}
                    {merchantConfig &&
                      merchantConfig.is_pragma_branding_visible && <Footer />}
                  </form>
                </Grid>
              ) : (
                <Grid item xs={12} md={6} style={{ paddingTop: "50px" }}>
                  <motion.div
                    initial={{ opacity: 0, y: 200 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.75 }}
                    style={{
                      color: "#333",
                      padding: "0px 40px",
                      justifyContent: "flex-start",
                      background: "#fff",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: "0px 0px 20px",
                      }}
                    >
                      <p style={{ fontSize: "24px", fontWeight: "600" }}>
                        Terms and Conditions:
                      </p>
                      <p
                        style={{ textAlign: "right", cursor: "pointer" }}
                        onClick={() => setTnc(false)}
                      >
                        {" "}
                        <CloseIcon
                          style={{
                            width: "24px",
                            height: "24px",
                            stroke: "#333",
                          }}
                        />{" "}
                      </p>
                    </div>
                    <div
                      style={{
                        maxHeight: "75vh",
                        overflow: "auto",
                        listStylePosition: "outside",
                        paddingRight: "10px",
                      }}
                    >
                      {parse(rmsConfig.tnc)}
                    </div>
                  </motion.div>
                  {merchantConfig &&
                    merchantConfig.is_pragma_branding_visible && <Footer />}
                </Grid>
              )}
            </Grid>
          ) : (
            <div
              className="login-wrapper"
              style={{
                justifyContent:
                  merchantConfig?.public_page_theme_configurations
                    ?.login_section_position == "left"
                    ? "flex-start"
                    : merchantConfig?.public_page_theme_configurations
                        ?.login_section_position === "center"
                    ? "center"
                    : "flex-end",
              }}
            >
              {confirmOtp ? (
                <Otp
                  inputValue={inputValue}
                  setConfirmOtp={setConfirmOtp}
                  response={response}
                  setResponse={setResponse}
                />
              ) : (
                <div
                  className="login-section"
                  style={{
                    background: `rgba(0,0,0,${merchantConfig?.public_page_theme_configurations?.login_section_opacity})`,
                    color: "#fff",
                    padding: "2rem",
                    border: "1px solid #fff",
                    borderRadius: merchantConfig.use_rounded_edge_buttons
                      ? "10px"
                      : "0px",
                    maxHeight: "max-content",
                    minHeight: "100px",
                    alignItems: merchantConfig?.public_page_theme_configurations
                      ?.cover_image_visible_in_desktop
                      ? "center"
                      : merchantConfig?.public_page_theme_configurations
                          ?.login_section_position === "left"
                      ? "flex-start"
                      : merchantConfig?.public_page_theme_configurations
                          ?.login_section_position === "right"
                      ? "flex-end"
                      : "center",
                  }}
                >
                  {(merchantConfig?.public_page_theme_configurations
                    ?.login_section_brand_logo_visible_in_desktop ||
                    merchantConfig?.public_page_theme_configurations
                      ?.login_section_brand_logo_visible_in_mobile) && (
                    <img
                      src={merchantConfig.brand_logo}
                      className="brand-logo"
                    />
                  )}
                  <p className="return-title">
                    {rmsConfig.home_page_request_creation_title}
                  </p>
                  <form
                    onSubmit={handleSubmit(loginHandler)}
                    style={{ maxWidth: "450px", width: "100%" }}
                  >
                    <label htmlFor="email" style={{ display: "block" }}>
                      <p className="input-label" style={{ color: "#fff" }}>
                        Email / Phone
                      </p>
                      <Controller
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true,
                          pattern:
                            /^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}|[0-9]{10})?$/,
                        }}
                        name="email"
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="email"
                            error={Boolean(errors.email)}
                            helperText={
                              errors.email
                                ? errors.email.type === "pattern"
                                  ? "Invalid email/phone. Please check entered information."
                                  : "Email / Phone is required"
                                : "Enter Email/Phone used for placing the order with us"
                            }
                            inputProps={{
                              placeholder: "9876543210/john@gmail.com",
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                border: "1px solid #fff",
                                borderRadius:
                                  merchantConfig.use_rounded_edge_buttons
                                    ? "4px"
                                    : "0px",
                                color: "#fff",
                              },
                              "& .MuiOutlinedInput-root input:focus": {
                                borderRadius:
                                  merchantConfig.use_rounded_edge_buttons
                                    ? "4px"
                                    : "0px",
                              },
                            }}
                            {...field}
                          />
                        )}
                      />
                    </label>
                    {otpSendError && (
                      <p
                        style={{
                          color: "rgba(221, 67, 93, 1)",
                          fontSize: "12px",
                        }}
                      >
                        Hmmm .... Can you please try again ?
                      </p>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      endIcon={otpLoading ? "" : <ArrowForwardIcon />}
                      className="otp-action"
                      color="primary"
                      style={{
                        width: "100%",
                        borderRadius: !merchantConfig.use_rounded_edge_buttons
                          ? "0px"
                          : "4px",
                        padding: "10px",
                        fontSize: "18px",
                        fontWeight: "600",
                        margin: "1rem 0px",
                      }}
                    >
                      {otpLoading ? (
                        <BeatLoader
                          color={merchantConfig.secondary_theme_color}
                        />
                      ) : (
                        "Proceed"
                      )}
                    </Button>
                    {rmsConfig.brand_home_page_note &&
                      rmsConfig.brand_home_page_note !== null &&
                      rmsConfig.brand_home_page_note !== "" && (
                        <p className="brand-note">
                          <ReadMore
                            content={rmsConfig.brand_home_page_note}
                            maxLength={490}
                          />
                        </p>
                      )}
                    <p
                      style={{
                        margin: "20px auto",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    >
                      By proceeding, you accept our{" "}
                      <span
                        onClick={() => setTnc(true)}
                        style={{
                          color: "#1d8dcc",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Terms & Conditions
                      </span>
                    </p>
                  </form>
                  {merchantConfig &&
                    merchantConfig.is_pragma_branding_visible && <Footer />}
                </div>
              )}
            </div>
          )}
          {tnc && (
            <SwipeableDrawer
              anchor="bottom"
              open={window.innerWidth < 800 ? tnc : false}
              onClose={() => setTnc(false)}
            >
              <StyledDrawerTnC>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <p style={{ fontSize: "14px", fontWeight: "600" }}>
                    Terms and Conditions:
                  </p>
                  <p
                    style={{
                      textAlign: "right",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => setTnc(false)}
                  >
                    {" "}
                    <CloseIcon
                      style={{ width: "18px", height: "18px", stroke: "#333" }}
                    />{" "}
                  </p>
                </div>
                <div
                  style={{
                    maxHeight: "80vh",
                    overflow: "auto",
                    listStylePosition: "outside",
                    paddingRight: "10px",
                  }}
                >
                  {parse(rmsConfig.tnc)}
                </div>
              </StyledDrawerTnC>
            </SwipeableDrawer>
          )}
        </StyledLogin>
      )}
    </StyledApp>
  );
}

export default Login;
