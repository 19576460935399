import styled from "styled-components";

export const StyledHeaderWrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5.5%;
  background: #fff;
  position: fixed;
  width: 100%;
  max-width: 1920px;
  z-index: 99;
  font-family: ${(props) => props.customFont};
  
  .back {
    color: var(--color-primary);
    font-weight: 500;
    text-transform: Capitalize;
    font-size: 1.25rem;
    font-family: "Public Sans";
  }

  .change {
    color: var(--color-primary);
    text-decoration: underline;
    font-size: 1.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .brand-logo {
    display: block;
    max-height: 50px;
    margin: auto;
    width: 100%;
  }

  .web-view-cart-btn {
    position: absolute;
    right: 50px;
    display: flex;
  }

  @media (max-width: 800px) {
    padding: 0 15px;
    margin-bottom: 10px;
    height: 60px;

    .web-view-cart-btn {
      display: none;
    }

    .back {
      font-size: 0.75rem;
    }

    .brand-logo {
      padding: 0 10px;
      max-width: 100px;
    }

    .change {
      font-size: 0.75rem;
    }
  }
`;
