import React, { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { StyledHeaderWrapper } from "./styled";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Menu, MenuItem } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantConfig } from "features/config/configSlice";
import {
  addEmail,
  addPhone,
  getEmail,
  getPhone,
} from "features/user/userSlice";
import {
  getSWHeader,
  getSelectedOrder,
  updateSWHeader,
  updateSelectedOrder,
} from "features/orders/orderSlice";
import {
  addPreviews,
  addProductType,
  addSelectedReason,
  addSelectedSubReason,
  addUploads,
  setImageUploads,
} from "features/product/productSlice";
import { addReturnedProducts } from "features/returnedProducts/returnedProductSlice";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

function Header({ ordername, requestPaymentStatus, disabled = true }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const merchantConfig = useSelector(getMerchantConfig);
  const phone = useSelector(getPhone);
  const email = useSelector(getEmail);
  const dispatch = useDispatch();
  const selectedOrder = useSelector(getSelectedOrder);
  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");
  const swHeaderParam = params.get("sw");
  const swHeader = useSelector(getSWHeader);

  useEffect(() => {
    if (location.pathname === "/orders") {
      if (swHeaderParam) {
        dispatch(updateSWHeader(true));
      } else {
        dispatch(updateSWHeader(false));
      }
    }
  }, []);

  const handleBack = () => {
    if (location.pathname === "/orders") {
      if (selectedOrder !== null && Object.keys(selectedOrder).length > 0) {
        dispatch(updateSelectedOrder({}));
      } else {
        let iframe = document.getElementById("sessionIframe");
        iframe.contentWindow.postMessage(
          { phone: null, email: null, topic: "PRAGMA" },
          "*"
        );
        dispatch(addPhone(null));
        dispatch(addEmail(null));
        let url = "/";
        if (encipherencode !== null && encipherencode !== undefined) {
          url += `?encipherencode=${encipherencode}`;
        }
        navigate(url);
      }
    } else if (
      location.pathname === "/products" ||
      location.pathname === "/tracking"
    ) {
      // dispatch(updateSelectedOrder({}));
      dispatch(addReturnedProducts([]));
      let url = "/orders";
      if (encipherencode !== null && encipherencode !== undefined) {
        url += `?encipherencode=${encipherencode}`;
      }
      navigate(url);
    } else if (location.pathname === "/return-cart") {
      let url = "/products";
      if (encipherencode !== null && encipherencode !== undefined) {
        url += `?encipherencode=${encipherencode}`;
      }
      navigate(url);
      dispatch(addProductType(null));
    }
  };

  const loginRedirect = (ev) => {
    if (ev === "orders") {
      let url = "/orders";
      if (encipherencode !== null && encipherencode !== undefined) {
        url += `?encipherencode=${encipherencode}`;
      }
      navigate(url);
    } else if (ev === "login") {
      let iframe = document.getElementById("sessionIframe");
      iframe.contentWindow.postMessage(
        { phone: null, email: null, topic: "PRAGMA" },
        "*"
      );
      let url = "/";
      if (encipherencode !== null && encipherencode !== undefined) {
        url += `?encipherencode=${encipherencode}`;
      }
      navigate(url);
      dispatch(addPhone(null));
      dispatch(addEmail(null));
    }
    dispatch(updateSelectedOrder({}));
    dispatch(setImageUploads([]));
    dispatch(addSelectedReason(null));
    dispatch(addSelectedSubReason(null));
    dispatch(addPreviews([]));
    dispatch(addUploads([]));
    dispatch(addReturnedProducts([]));
    dispatch(addProductType(null));
  };

  return (
    <StyledHeaderWrapper
      customFont={
        merchantConfig.google_font_family !== null
          ? merchantConfig.google_font_family
          : "Poppins,sans-serif"
      }
      swHeader={swHeader}
    >
      {/* {!swHeader && (
        <Button
          variant="text"
          onClick={() => handleBack()}
          style={{
            color: "#1C1B1B",
            visibility:
              location.pathname === "/success" ||
              swHeader ||
              requestPaymentStatus == "pending"
                ? "hidden"
                : "initial",
          }}
          startIcon={<ArrowBackIcon />}
        >
          <span
            className="back"
            style={{
              fontFamily:
                merchantConfig.google_font_family !== null
                  ? merchantConfig.google_font_family
                  : "Poppins,sans-serif",
            }}
          >
            Back
          </span>
        </Button>
      )} */}
      <div style={{ minWidth: "50px" }}>
        <img
          src={merchantConfig.brand_logo}
          alt={merchantConfig.shop_display_name}
          className="brand-logo"
        />
      </div>
      {swHeader && (
        <div
          onClick={() => {
            window.parent.postMessage(
              { topic: "RMS", message: "close_rms" },
              "*"
            );
          }}
          style={{ cursor: "pointer", position: "absolute", right: "24px" }}
        >
          <CloseIcon />
        </div>
        // ) : location.pathname === "/orders" ||
        //   location.pathname === "/tracking" ? (
        //   <p
        //     className="change"
        //     style={{
        //       visibility:
        //         swHeader || requestPaymentStatus == "pending"
        //           ? "hidden"
        //           : "visible",
        //     }}
        //     onClick={(e) => {
        //       setAnchorEl(e.currentTarget);
        //       let iframe = document.getElementById("sessionIframe");
        //       iframe.contentWindow.postMessage(
        //         {
        //           phone: null,
        //           email: null,
        //           topic: "PRAGMA",
        //         },
        //         "*"
        //       );
        //     }}
        //   >
        //     Change email/phone
        //   </p>
        // ) : (
        //   <p
        //     className="change"
        //     style={{
        //       visibility: swHeader
        //         ? "hidden"
        //         : location.pathname !== "/success"
        //         ? "visible"
        //         : "hidden",
        //     }}
        //     onClick={(e) => setAnchorEl(e.currentTarget)}
        //   >
        //     Order Name {ordername && !ordername.startsWith('#') ? `#${ordername}` : ordername}
        //     <ExpandMoreIcon />{" "}
        //   </p>
      )}
      {/* <Menu
        anchorEl={anchorEl}
        open={open}
        disableAutoFocusItem={true}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {location.pathname === "/orders" ? (
          <MenuItem
            className="menu-item"
            style={{
              padding: "15px 30px",
              display: "block",
              textAlign: "center",
              cursor: "initial",
            }}
          >
            <p style={{ margin: "0px 0px 10px", color: "#5b5b5b" }}>
              Signed In as
            </p>
            <p style={{ margin: "10px 0px", color: "#5b5b5b" }}>
              {email && email !== null && email !== ""
                ? email
                : "+91 - " + phone}{" "}
            </p>
            <Button
              color="primary"
              variant="contained"
              onClick={() => loginRedirect("login")}
              style={{ padding: "10px 20px", fontSize: "14px" }}
            >
              Change
            </Button>
          </MenuItem>
        ) : (
          <Fragment>
            <MenuItem onClick={() => loginRedirect("orders")}>
              Change order
            </MenuItem>
            <MenuItem onClick={() => loginRedirect("login")}>
              Change email/phone
            </MenuItem>
          </Fragment>
        )}
      </Menu> */}
    </StyledHeaderWrapper>
  );
}

export default Header;
